
import { reactive, watch } from '@vue/runtime-core'
import { ref, defineComponent } from 'vue'
import { imageUrl } from '@/utils/image'

export default defineComponent({
  setup() {
    const dialog = ref<boolean>(false)
    const imgView = ref<string>('')
    const view = (path: string) => {
      dialog.value = true
      imgView.value = path
    }
    const close = () => {
      dialog.value = false
    }
    return {
      view,
      dialog,
      imgView,
      close,
      imageUrl,
    }
  },
})
