
import { defineComponent, ref, computed, reactive, watch, onMounted } from 'vue'
import productDataService from '@/services/productDataService'
import {
  IProductVariant,
  TUploadDesignResponse,
} from '@/interfaces/product-warehouse'
import useUploadRepositories from '@/repositories/useUploadRepositories'
import { useNotification } from '@/composables'
import { View } from '@element-plus/icons'
import ImageViewDialog from './ImageViewDialog.vue'

import UploadDesign from './UploadDesign.vue'
import UploadBarcode from './UploadBarcode.vue'

import { ArrowDown } from '@element-plus/icons'
export default defineComponent({
  name: 'ItemSKUList',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UploadDesign,
    UploadBarcode,
    ImageViewDialog,
    ArrowDown,
  },
  setup() {
    const state = productDataService.state
    const { error } = useNotification()
    const onSelectionChange = (val: IProductVariant[]): void => {
      state.multipleSelection = val
    }
    const onUploadFileDesign = async (
      data: TUploadDesignResponse
    ): Promise<void> => {
      try {
        console.log(data.productVariant)
        data.productVariant.map(v => (v.artwork1 = data.file.path))
      } catch (err: any) {
        error(err.message)
      }
    }
    const imageViewDialog = ref<InstanceType<typeof ImageViewDialog>>()

    const view = (path: string) => {
      imageViewDialog.value?.view(path)
    }
    return {
      state,
      onSelectionChange,
      onUploadFileDesign,
      view,
      ACTION: productDataService.ACTION,
      imageViewDialog,
    }
  },
})
