
import { reactive, ref } from 'vue'
import { useResource } from '@/services'
import { AxiosResponse } from 'axios'
import { IProductWarehouse, Params , IProductVariant, IProductOption, IClass, IProductAction, IStatus} from '@/interfaces/product-warehouse'
import { useFetchData } from '@/composables'

import {Catalog} from '@/interfaces/catalog' 
class ProductDataService implements IClass{
    private resource: {products:string, catalogs:string};
    public  state:  IStatus;
    public ACTION: IProductAction;
    public clearData: IProductWarehouse;
    constructor(){
        this.resource = { 
            products: "products", 
            catalogs: "catalogs"
        }
        this.ACTION = {
            CREATE: 1,
            UPDATE:2,
            DETAIL: 3
          }
        this.state = reactive<IStatus>({
            total: 0,
            isLoading: false,
            isSubmited: this.ACTION.CREATE,
            products: [] as IProductWarehouse[], 
            product: {
                title: '',
                mockup: '',
                shopifyId: '',
                amazonId:'',
                artwork1:'',
                artwork2:'',
                catalogId:'',
                catalogName:'',
                catalogProductCode:'',
                otherMockup: [],
                options:[{
                    name: "",
                    values:[]
                }] as  IProductOption[],
                variants:[{
                    warehouseIds: [],
                    skuShopify: '',
                    skuAmazon: '',
                    title: '',
                    sku: '',
                    barcode:'',
                    barcodeFile:'',
                    option1: '',
                    option2: '',
                    option3: '',
                    artwork1: '',
                    artwork2: '',
                    artwork3: '',
                    artwork4: '',
                    artwork5: '',
                    catalogVariantCode:'',
                    catalogVariantColorCode:'',
                    catalogVariantColorName:'',
                    catalogVariantName:'',
                    catalogVariantSizeCode:'',
                    catalogVariantSizeName:'',
                    
                }] as  IProductVariant[],
            } as IProductWarehouse,  
            multipleSelection: [] as IProductVariant[],
            params:{
                search:'',
                page: 1,
                limit: 50
            } as Params,  
            catalogs: [] as Catalog[] ,
            catalogId: '',
            formTitle: 'Create Product',
            isContentShown: false
          })
          this.clearData  = this.state.product;
    }
    async getAll(params: Params) : Promise<AxiosResponse>{
        const { getAll } = useResource(this.resource.products);
        try {
            return await getAll(params) as AxiosResponse
        } catch (error) {
            return error as AxiosResponse
        }
    }
    async count(){
        try {
            const {  count } = useResource(this.resource.products);
            return  await count() as AxiosResponse;
        } catch (error) {
            return error as AxiosResponse
        }
    }
    async getAllCatalog(params: Params) : Promise<AxiosResponse>{
        const { getAll } = useResource(this.resource.catalogs);
        try {
            return await getAll(params) as AxiosResponse
        } catch (error) {
            return error as AxiosResponse
        }
    }
    async getById(id: string | string[]) {
        const { get } = useResource(this.resource.products);
        try {
            return await get(id) as AxiosResponse
        } catch (error) {
            return error as AxiosResponse
        }
    }
    async create(product: IProductWarehouse) {
        const { add } = useResource(this.resource.products);
        try {
            return await add(product) as AxiosResponse
        } catch (error ) {
            return error as AxiosResponse
        }
    }
    async deleteById(id: string | string[]) {
        const { deleteById } = useResource(this.resource.products);
        try {
            return await deleteById(id) as AxiosResponse
        } catch (error) {
            return error as AxiosResponse
        }
    }
    clear(){
        this.state.product = this.clearData;
    }
    async update( product: IProductWarehouse) {
        const { edit } = useResource(`${this.resource.products}/${product._id}`);
        try {
            return await edit(product) as AxiosResponse
        } catch (error ) {
            return error as AxiosResponse
        }
    }
    async productFetchData(){
        const { documents, fetchData, isLoading, total } = useFetchData(this.resource.products, this.state.params)
        await fetchData();
        this.state.products = documents.value as IProductWarehouse[]
    }
    

}
export default new ProductDataService();
