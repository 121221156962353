
import { defineComponent, reactive, ref, watch, onMounted, version } from 'vue'
import { useNotification } from '@/composables'
import UploadImage from './UploadImage.vue'
import Variant from './Variant.vue'
import productDataService from '@/services/productDataService'
import { Catalog } from '@/interfaces/catalog'
import { useRouter } from 'vue-router'
import {
  Params,
  IProductVariant,
  IProductWarehouse,
  IProductOption,
} from '@/interfaces/product-warehouse'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import { imageUrl } from '@/utils/image'
export default defineComponent({
  name: 'Create',
  emits: ['reload', 'callback'],
  props: {},
  components: { Variant },

  setup(props, context) {
    const state = productDataService.state
    const formAddProduct = ref()
    const { error, success } = useNotification()
    const { getCatalogs } = userCatalogRepositories()
    const router = useRouter()
    const callbackDesignImage = (
      setData: string,
      files: Array<string>
    ): void => {
      state.product.artwork2 = files[0]
    }

    const callbackPreviewImage = (
      setData: string,
      files: Array<string>
    ): void => {
      state.product.artwork1 = files[0]
    }
    const getCatalog = async (query?: string) => {
      const info = JSON.parse(localStorage.getItem('info') || '')
      console.log('info', info)

      const params = { ...state.params }
      params.basecost = info?.sellerTeam?.basecost.id
      if (query) params.search = query
      let res = await getCatalogs(params)
      state.catalogs = res as Catalog[]
    }
    const onChangeCatalog = (catalogId: string): void => {
      if (state.catalogs) {
        const catalog = state.catalogs.find(
          (c: any) => c._id === catalogId
        ) as Catalog

        if (
          state.isSubmited === productDataService.ACTION.UPDATE &&
          state.catalogId === catalogId
        ) {
          const product = state.products.find(
            (p: any) => p._id === state.product?._id
          ) as IProductWarehouse

          state.product.variants = product?.variants
        } else {
          state.product.catalogName = catalog.name
          state.product.catalogProductCode = catalog.productCode
          let productVariants: IProductVariant[] = []
          if (catalog.variants?.length) {
            catalog.variants.forEach(v => {
              const productVariant: IProductVariant = {
                title: v.catalogVariantCode,
                skuShopify: '',
                skuAmazon: '',
                sku: '',
                barcode: '',
                barcodeFile: '',
                option1: v.size?.code,
                option2: v.color?.code,
                artwork1: '',
                catalogVariantName: v.catalogVariantCode,
                catalogVariantCode: v.catalogVariantCode,
                catalogVariantSizeName: v.size?.name,
                catalogVariantSizeCode: v.size?.code,
                catalogVariantColorCode: v.color?.name,
                catalogVariantColorName: v.color?.code,
                catalogVariantId: v._id,
              }
              productVariants.push(productVariant)
            })
            state.product.variants = productVariants
          }
        }
      }
    }
    const onSubmit = (): void => {
      formAddProduct.value.validate(async (valid: boolean): Promise<void> => {
        if (valid) {
          const { data, status } =
            state.isSubmited === productDataService.ACTION.CREATE
              ? await productDataService.create(state.product)
              : await productDataService.update(state.product)
          if (status === 201 || status === 200) {
            //dialogVisible.value = false
            state.isContentShown = false
            context.emit('callback')
            success(data.message)
          } else {
            error(data)
          }
        }
      })
    }
    const open = (): void => {
      state.isContentShown = true
    }
    const cancel = (): void => {
      console.log('cancel')
      productDataService.clear()
      console.log('product-->', state.product)

      state.isContentShown = false
    }

    const dialog = ref<boolean>(false)
    const imgView = ref<string>('')
    const view = (path: string) => {
      dialog.value = true
      imgView.value = path
    }

    const hoverIndex = ref(-1)
    const onMouse = (index: number) => {
      hoverIndex.value = index
    }

    const onMouseMove = () => {
      hoverIndex.value = -1
    }

    return {
      cancel,
      state,
      ACTION: productDataService.ACTION,
      callbackDesignImage,
      callbackPreviewImage,
      open,
      onChangeCatalog,
      onSubmit,
      formAddProduct,
      getCatalog,
      onMouseMove,
      onMouse,
      imageUrl,
    }
  },
})
