
import { defineComponent, reactive } from 'vue'
import { UploadFilled } from '@element-plus/icons'
import useUploadRepositories from '@/repositories/useUploadRepositories'
import {
  TUploadDesignResponse,
  IProductVariant,
  FileItem,
} from '@/interfaces/product-warehouse'

export default defineComponent({
  components: { UploadFilled },
  props: {
    productVariant: {
      type: Array,
      default: () => [],
    },
    content: {
      type: String,
      default: 'Upload Design',
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { onUploadImage } = useUploadRepositories()
    const state = reactive({
      isLoading: false,
    })

    const uploadVariantDesign = async (file: any) => {
      try {
        state.isLoading = true
        let res = await onUploadImage(file.raw)
        const data: TUploadDesignResponse = {
          productVariant: props?.productVariant as IProductVariant[],
          file: res as FileItem,
        }
        emit('onUploadFileDesign', data)
        state.isLoading = false
      } catch (error) {
        state.isLoading = false
      }
    }
    return { state, uploadVariantDesign }
  },
})
