import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"vertical-align":"middle"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_upload_filled = _resolveComponent("upload-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createBlock(_component_el_upload, {
    "show-file-list": false,
    "auto-upload": false,
    "on-change": _ctx.uploadVariantDesign
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_button, {
        type: "text",
        loading: _ctx.state.isLoading,
        size: "small"
      }, {
        default: _withCtx(() => [
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_el_icon, {
                  size: 20,
                  style: {"vertical-align":"middle"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_upload_filled)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.content), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _: 1
  }, 8, ["on-change"]))
}